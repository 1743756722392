import { graphql } from "gatsby";
import React from "react";
import { List, ListItem } from "../List";
import { Image } from "../Image";
import "./styles.css";

const HomeWhyDoINeedAFilter = ({ background, tap, title, points }) => {
  return (
    <div className="HomeWhyDoINeedAFilter">
      <div className="HomeWhyDoINeedAFilter__backgroundContainer">
        <Image
          className="HomeWhyDoINeedAFilter__background"
          image={background}
          alt=""
        />
        <Image className="HomeWhyDoINeedAFilter__tap" image={tap} alt="" />
      </div>

      <div className="[ HomeWhyDoINeedAFilter__content ] [ Content md:Content_small sm:Content_medium  ]">
        <div className="HomeWhyDoINeedAFilter__info">
          <div className="[ Font_headerSmall Color_darkBlue ]">{title}</div>
          <List className="HomeWhyDoINeedAFilter__features">
            {points.map(point => (
              <ListItem key={point}>{point}</ListItem>
            ))}
          </List>
        </div>
      </div>
    </div>
  );
};

HomeWhyDoINeedAFilter.getLivePreviewData = ({ data, getAsset }) => ({
  whyDoINeedAFilter: {
    ...data.whyDoINeedAFilter,
    background: getAsset(data.whyDoINeedAFilter.background).url,
    tap: getAsset(data.whyDoINeedAFilter.tap).url
  }
});

const homeHomeWhyDoINeedAFilterQuery = graphql`
  fragment HomeWhyDoINeedAFilterFragment on MarkdownRemarkFrontmatter {
    whyDoINeedAFilter {
      title
      points
      background {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      tap {
        childImageSharp {
          fluid(maxWidth: 652, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;

export { HomeWhyDoINeedAFilter, homeHomeWhyDoINeedAFilterQuery };
