import { graphql } from "gatsby";
import React from "react";
import { Form } from "../Form";
import { HowWeWorkInfo } from "./components/HowWeWorkContent";
import "./styles.css";

const HomeHowWeWork = ({
  pageId,
  title,
  textWaterSample,
  textAnalysis,
  stages,
  form,
}) => {
  return (
    <div className="[ HomeHowWeWork ] [ Content md:Content_small sm:Content_small ]">
      <HowWeWorkInfo
        className="HomeHowWeWork__howWeWorkInfo"
        title={title}
        textWaterSample={textWaterSample}
        textAnalysis={textAnalysis}
        stages={stages}
      />

      <Form
        className="HomeHowWeWork__order"
        pageId={pageId}
        formId="Как мы работаем"
        {...form}
      />
    </div>
  );
};

HomeHowWeWork.getLivePreviewData = ({ data, getAsset }) => ({
  howWeWork: {
    ...data.howWeWork,
    stages: data.howWeWork.stages.map((stage) => ({
      ...stage,
      icon: { publicURL: getAsset(stage.icon).url },
    })),
  },
});

const homeHowWeWorkQuery = graphql`
  fragment HomeHowWeWorkFragment on MarkdownRemarkFrontmatter {
    howWeWork {
      title
      textWaterSample
      textAnalysis
      stages {
        name
        icon {
          publicURL
        }
      }
      form {
        subtitle
        title
        text
        buttonLabel
        consentToTheProcessing
      }
    }
  }
`;

export { HomeHowWeWork, homeHowWeWorkQuery };
