import { useEffect, useState } from "react";

const useWidth = elementRef => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      const style = getComputedStyle(elementRef.current);
      const clientWidth = parseFloat(style.width);
      const paddingLeft = parseFloat(style.paddingLeft);
      const paddingRight = parseFloat(style.paddingRight);

      setWidth(clientWidth - paddingLeft - paddingRight);
    };

    handleResize();
    window.addEventListener("resize", handleResize, { passive: true });
    return () => window.removeEventListener("resize", handleResize);
  }, [elementRef]);

  return width;
};

export { useWidth };
