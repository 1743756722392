import React from "react";
import cn from "classnames";

import "./styles.css";
import { Stages } from "../Stages";

const HowWeWorkInfo = ({
  className,
  title,
  textWaterSample,
  textAnalysis,
  stages
}) => {
  return (
    <div className={cn(className, "HowWeWorkInfo")}>
      <h2 className="[ Color_darkBlue Font_headerBig md:Font_header sm:Font_headerSmall ]">
        {title}
      </h2>

      <div className="HowWeWorkInfo__content">
        <div className="HowWeWorkInfo__text">
          <div className="[ HowWeWorkInfo__beforeStages ] [ Color_darkBlue Font_textRegular ]">
            {textWaterSample}
          </div>

          <Stages
            className="HowWeWorkInfo__stages HowWeWorkInfo__stages_desktop"
            stages={stages}
          />

          <div className="[ HowWeWorkInfo__afterStages ] [ Color_darkBlue Font_textRegular ]">
            {textAnalysis}
          </div>
        </div>

        <Stages
          className="HowWeWorkInfo__stages HowWeWorkInfo__stages_mobile"
          stages={stages}
        />
      </div>
    </div>
  );
};

export { HowWeWorkInfo };
