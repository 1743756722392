import { graphql } from "gatsby";
import React from "react";
import { Image } from "../Image";
import "./styles.css";

const bytesInMegabyte = 1024 * 1024;

const getMegabytes = (size) => {
  const megabytes = size / bytesInMegabyte;

  return megabytes > 0.1 ? megabytes.toFixed(1) : "0.1";
};

const HomeAnalysisExample = ({ background, title, text, image, documents }) => {
  return (
    <div id="analysis" className="[ HomeAnalysisExample ] [ Color_darkBlue ]">
      <div className="HomeAnalysisExample__backgroundContainer">
        <Image className="HomeAnalysisExample__background" image={background} />
      </div>

      <div className="[ HomeAnalysisExample__content ] [ Content md:Content_small sm:Content_medium ]">
        <div className="HomeAnalysisExample__info">
          <div className="HomeAnalysisExample__textInfo">
            <h2 className="[ Font_headerMiddle sm:Font_headerSmall ]">
              {title}
            </h2>
            <div className="[ HomeAnalysisExample__text ] [ Font_bigText sm:Font_textRegular ]">
              {text}
            </div>
          </div>

          <div className="[ HomeAnalysisExample__tableWrapper ] [ FullBleed_scroll ]">
            <Image
              className="HomeAnalysisExample__table"
              image={image}
              alt="Таблица с результатами анализа воды"
            />
          </div>
        </div>

        <div className="[ HomeAnalysisExample__documents ] [ Font_smallText ]">
          {documents.map(({ name, document }) => (
            <div key={name}>
              <a
                className="[ Color_darkBlue ]"
                href={document.publicURL}
                target="_blank"
                rel="noopener noreferrer"
              >
                {name}
              </a>{" "}
              <span className="[ Color_darkBlue48 ] ">
                ({getMegabytes(document.size)} Мб)
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

HomeAnalysisExample.getLivePreviewData = ({ data, getAsset }) => {
  return {
    analysisExample: {
      ...data.analysisExample,
      background: getAsset(data.background).url,
      image: getAsset(data.image).url,
    },
  };
};

const homeAnalysisExampleQuery = graphql`
  fragment HomeAnalysisExampleFragment on MarkdownRemarkFrontmatter {
    analysisExample {
      background {
        childImageSharp {
          fluid(maxWidth: 1796, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      title
      text
      image {
        childImageSharp {
          fixed(width: 640, quality: 90) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      documents {
        name
        document {
          publicURL
          size
        }
      }
    }
  }
`;

export { HomeAnalysisExample, homeAnalysisExampleQuery };
