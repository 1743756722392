import { graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import { Image } from "../Image";
import { Slider } from "../Slider";
import {
  SliderCard,
  SliderCardContent,
  SliderCardImage,
} from "../Slider/SliderCard";
import "./styles.css";

const useInnerWidth = () => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    handleResize();
    window.addEventListener("resize", handleResize, { passive: true });
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return width;
};

const HomeCompletedWorks = ({ title, examples }) => {
  return (
    <div className="[ HomeCompletedWorks ]">
      <div className="[ Content ]">
        <h2 className="[ Font_headerBig sm:Font_headerSmall Color_darkBlue ]">
          {title}
        </h2>
      </div>

      <Slider className="[ HomeCompletedWorks__slider ] [ Content md:Content_small ]">
        {examples.map(({ photo, title, address }) => (
          <SliderCard key={title}>
            <SliderCardImage>
              <Image className="Solutions__cardImage" image={photo} alt="" />
            </SliderCardImage>
            <SliderCardContent className="Solutions__cardContent">
              <h3 className="[ Font_textSemiBold sm:Font_smallText Color_darkBlue80 ]">
                {title}
              </h3>
              <p className="[ Font_smallText Color_darkBlue48 ]">{address}</p>
            </SliderCardContent>
          </SliderCard>
        ))}
      </Slider>
    </div>
  );
};

HomeCompletedWorks.getLivePreviewData = ({ data, getAsset }) => {
  return {
    completedWorks: {
      ...data.completedWorks,
      examples: data.completedWorks.examples.map((example) => ({
        ...example,
        photo: getAsset(example.photo).url,
      })),
    },
  };
};

const homeCompletedWorksQuery = graphql`
  fragment HomeCompletedWorksFragment on MarkdownRemarkFrontmatter {
    completedWorks {
      title
      examples {
        photo {
          childImageSharp {
            fluid(maxWidth: 936) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        address
      }
    }
  }
`;

export { HomeCompletedWorks, homeCompletedWorksQuery };
