import { graphql, Link } from "gatsby";
import React from "react";
import { Case } from "../../icons/Case";
import { House } from "../../icons/House";
import { Knife } from "../../icons/Knife";
import { Button } from "../Button";
import { WithBackground } from "../WithBackground";
import { HomeHeroSolutions } from "./HomeHeroSolutions";
import "./styles.css";

const HomeHero = ({
  background,
  title,
  slogan,
  contactUs,
  houseFilters,
  businessFilters,
  kitchenFilters,
}) => {
  const solutions = [
    {
      ...houseFilters,
      icon: <House />,
      href: "/catalog?activePage=1&category=Для%20всего%20дома",
    },
    {
      ...businessFilters,
      icon: <Case />,
      href: "/catalog?activePage=1&category=Для%20бизнеса",
    },
    {
      ...kitchenFilters,
      icon: <Knife />,
      href: "/catalog?activePage=1&category=Для%20кухни",
    },
  ];

  return (
    <div className="HomeHero">
      <WithBackground
        className="HomeHero__hero"
        image={background}
        alt="Дом, стоящий на фоне гор."
      >
        <div className="[ HomeHero__content ] [ Content md:Content_small sm:Content_medium ]">
          <p className="[ HomeHero__title ] [ Color_darkBlue48 Font_headerSmall sm:Font_headerSmallest ]">
            {title}
          </p>
          <h1 className="[ HomeHero__tagline ] [ Color_darkBlue Font_headerMiddle sm:Font_headerSmallMiddle ]">
            {slogan}
          </h1>
          <Link to="/#contact-us">
            <Button className="HomeHero__button">{contactUs}</Button>
          </Link>
        </div>
      </WithBackground>

      <HomeHeroSolutions solutions={solutions} />
    </div>
  );
};

HomeHero.getLivePreviewData = ({ data, getAsset }) => ({
  hero: {
    ...data.hero,
    background: getAsset(data.hero.background).url,
  },
});

const heroPageQuery = graphql`
  fragment HomeHeroFragment on MarkdownRemarkFrontmatter {
    hero {
      background {
        childImageSharp {
          fluid(maxWidth: 3840, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      title
      slogan
      contactUs

      houseFilters {
        title
        text
        linkTitle
        startPrice
      }

      businessFilters {
        title
        text
        linkTitle
        startPrice
      }

      kitchenFilters {
        title
        text
        linkTitle
        startPrice
      }
    }
  }
`;

export { HomeHero, heroPageQuery };
