import { graphql } from "gatsby";
import React from "react";
import { List, ListItem } from "../List";
import { Installation } from "./Installation";
import "./styles.css";
import BackgroundImage from "gatsby-background-image";

const HomeHowDoWeSelectEquipment = ({
  background,
  subtitle,
  factors,
  title,
  installation
}) => {
  return (
    <BackgroundImage
      className="HomeHowDoWeSelectEquipment"
      fluid={background.childImageSharp.fluid}
    >
      <div className="[ HomeHowDoWeSelectEquipment__info ] [ Content md:Content_small sm:Content_medium ]">
        <div className="[ Font_textSemiBold sm:Font_smallText Color_darkBlue48 ]">
          {subtitle}
        </div>
        <div className="[ HomeHowDoWeSelectEquipment__title ] [ Font_headerBig sm:Font_headerMiddle Color_darkBlue ]">
          {title}
        </div>

        <List className="HomeHowDoWeSelectEquipment__factors">
          {factors.map(factor => (
            <ListItem key={factor}>{factor}</ListItem>
          ))}
        </List>
      </div>

      <div className="[ Content md:Content_medium ]">
        <Installation installation={installation} />
      </div>
    </BackgroundImage>
  );
};

HomeHowDoWeSelectEquipment.getLivePreviewData = ({ data, getAsset }) => ({
  howDoWeSelectEquipment: {
    ...data.howDoWeSelectEquipment,
    background: {
      childImageSharp: {
        fluid: {
          src: getAsset(data.howDoWeSelectEquipment.background).url
        }
      }
    },
    installation: {
      ...data.howDoWeSelectEquipment.installation,
      image: {
        publicURL: getAsset(data.howDoWeSelectEquipment.installation.image).url
      }
    }
  }
});

const homeHowDoWeSelectEquipmentQuery = graphql`
  fragment HomeHowDoWeSelectEquipmentFragment on MarkdownRemarkFrontmatter {
    howDoWeSelectEquipment {
      background {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      subtitle
      title
      factors
      installation {
        image {
          publicURL
        }
        title
        text
      }
    }
  }
`;

export { HomeHowDoWeSelectEquipment, homeHowDoWeSelectEquipmentQuery };
