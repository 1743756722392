import { graphql, Link } from "gatsby";
import React from "react";
import { Image } from "../Image";
import "./styles.css";

const HomeBottomBanner = ({
  isVisible,
  bannerImage,
  bannerImageTablet,
  bannerImagePhone,
  link,
}) => {
  return isVisible ? (
    <Link to={link}>
      <Image className="HomeBottomBanner__banner" image={bannerImage} />
      <Image
        className="HomeBottomBanner__bannerTablet"
        image={bannerImageTablet}
      />
      <Image
        className="HomeBottomBanner__bannerPhone"
        image={bannerImagePhone}
      />
    </Link>
  ) : null;
};

HomeBottomBanner.getLivePreviewData = ({ data, getAsset }) => ({
  bottomBanner: {
    ...data.bottomBanner,
    bannerImage: {
      childImageSharp: {
        fluid: {
          src: getAsset(data.bottomBanner.bannerImage).url,
        },
      },
    },
    bannerImageTablet: {
      childImageSharp: {
        fluid: {
          src: getAsset(data.topBanner.bannerImageTablet).url,
        },
      },
    },
    bannerImagePhone: {
      childImageSharp: {
        fluid: {
          src: getAsset(data.topBanner.bannerImagePhone).url,
        },
      },
    },
  },
});

const homeBottomBannerQuery = graphql`
  fragment HomeBottomBannerFragment on MarkdownRemarkFrontmatter {
    bottomBanner {
      isVisible
      bannerImage {
        childImageSharp {
          fluid(maxWidth: 2880, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      bannerImageTablet {
        childImageSharp {
          fluid(maxWidth: 2400, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      bannerImagePhone {
        childImageSharp {
          fluid(maxWidth: 1152, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }

      link
    }
  }
`;

export { HomeBottomBanner, homeBottomBannerQuery };
