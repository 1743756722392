import { graphql } from "gatsby";
import React from "react";
import Helmet from "react-helmet";
import { HomeAnalysisExample } from "../components/HomeAnalysisExample";
import { HomeBottomBanner } from "../components/HomeBottomBanner";
import { HomeCompletedWorks } from "../components/HomeCompletedWorks";
import { HomeHero } from "../components/HomeHero";
import { HomeHowDoWeSelectEquipment } from "../components/HomeHowDoWeSelectEquipment";
import { HomeHowWeWork } from "../components/HomeHowWeWork";
import { HomeOurSolutions } from "../components/HomeOurSolutions";
import { HomeOurTeam } from "../components/HomeOurTeam";
import { HomeTopBanner } from "../components/HomeTopBanner";
import { HomeWhyDoINeedAFilter } from "../components/HomeWhyDoINeedAFilter";
import { Layout } from "../components/Layout";
import { Maintenance } from "../components/Maintenance";

const IndexPageTemplate = ({ layout, ...props }) => {
  return (
    <>
      <Helmet>
        <title>{props.seo.title}</title>
        <meta name="Description" content={props.seo.description} />
        <meta name="keywords" content={props.seo.keywords} />
      </Helmet>

      <HomeTopBanner {...props.topBanner} />
      <HomeHero {...props.hero} />
      <HomeBottomBanner {...props.bottomBanner} />
      <HomeWhyDoINeedAFilter {...props.whyDoINeedAFilter} />
      <HomeOurSolutions {...props.ourSolutions} />
      <HomeHowWeWork pageId={props.pageId} {...props.howWeWork} />
      <HomeAnalysisExample {...props.analysisExample} />
      <HomeHowDoWeSelectEquipment {...props.howDoWeSelectEquipment} />
      <HomeCompletedWorks {...props.completedWorks} />
      {layout.maintenance && <Maintenance {...layout.maintenance} />}
      <HomeOurTeam {...props.ourTeam} />
    </>
  );
};

IndexPageTemplate.getLivePreviewData = (props) => ({
  ...props.data,
  ...Layout.getLivePreviewData(props),
  ...HomeTopBanner.getLivePreviewData(props),
  ...HomeHero.getLivePreviewData(props),
  ...HomeBottomBanner.getLivePreviewData(props),
  ...HomeWhyDoINeedAFilter.getLivePreviewData(props),
  ...HomeOurSolutions.getLivePreviewData(props),
  ...HomeHowWeWork.getLivePreviewData(props),
  ...HomeAnalysisExample.getLivePreviewData(props),
  ...HomeHowDoWeSelectEquipment.getLivePreviewData(props),
  ...HomeOurTeam.getLivePreviewData(props),
  ...HomeCompletedWorks.getLivePreviewData(props),
});

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout {...data.layout.frontmatter} pageId={frontmatter.pageId}>
      <IndexPageTemplate layout={data.layout.frontmatter} {...frontmatter} />
    </Layout>
  );
};

const pageQuery = graphql`
  query IndexPageTemplate {
    layout: markdownRemark(frontmatter: { meta: { eq: "layout" } }) {
      frontmatter {
        ...LayoutFragment
        ...MaintenanceFragment
      }
    }

    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        pageId

        seo {
          title
          description
          keywords
        }

        ...HomeTopBannerFragment
        ...HomeHeroFragment
        ...HomeBottomBannerFragment
        ...HomeWhyDoINeedAFilterFragment
        ...HomeOurSolutionsFragment
        ...HomeHowWeWorkFragment
        ...HomeAnalysisExampleFragment
        ...HomeHowDoWeSelectEquipmentFragment
        ...HomeOurTeamFragment
        ...HomeCompletedWorksFragment
      }
    }
  }
`;

export default IndexPage;

export { pageQuery, IndexPageTemplate };
