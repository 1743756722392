import { graphql, Link } from "gatsby";
import React from "react";
import { Image } from "../Image";
import "./styles.css";

const HomeTopBanner = ({
  isVisible,
  bannerImage,
  bannerImageTablet,
  bannerImagePhone,
  link,
}) => {
  return isVisible ? (
    <Link to={link}>
      <Image className="HomeTopBanner__banner" image={bannerImage} />
      <Image
        className="HomeTopBanner__bannerTablet"
        image={bannerImageTablet}
      />
      <Image className="HomeTopBanner__bannerPhone" image={bannerImagePhone} />
    </Link>
  ) : null;
};

HomeTopBanner.getLivePreviewData = ({ data, getAsset }) => ({
  topBanner: {
    ...data.topBanner,
    bannerImage: {
      childImageSharp: {
        fluid: {
          src: getAsset(data.topBanner.bannerImage).url,
        },
      },
    },
    bannerImageTablet: {
      childImageSharp: {
        fluid: {
          src: getAsset(data.topBanner.bannerImageTablet).url,
        },
      },
    },
    bannerImagePhone: {
      childImageSharp: {
        fluid: {
          src: getAsset(data.topBanner.bannerImagePhone).url,
        },
      },
    },
  },
});

const homeTopBannerQuery = graphql`
  fragment HomeTopBannerFragment on MarkdownRemarkFrontmatter {
    topBanner {
      isVisible
      bannerImage {
        childImageSharp {
          fluid(maxWidth: 2880, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      bannerImageTablet {
        childImageSharp {
          fluid(maxWidth: 2400, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      bannerImagePhone {
        childImageSharp {
          fluid(maxWidth: 1152, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      link
    }
  }
`;

export { HomeTopBanner, homeTopBannerQuery };
