import React from "react";

const Installation = ({ installation }) => {
  return (
    <div className="HomeHowDoWeSelectEquipment__installation ">
      <img
        className="HomeHowDoWeSelectEquipment__track"
        src={installation.image.publicURL}
        alt=""
      />
      <div className="[ HomeHowDoWeSelectEquipment__installationInfo ] [ Color_white ]">
        <div className="[ Font_headerMiddle sm:Font_headerSmall ]">
          {installation.title}
        </div>
        <div className="[ HomeHowDoWeSelectEquipment__installation_text ] [ Font_bigText sm:Font_textRegular ] ">
          {installation.text}
        </div>
      </div>
    </div>
  );
};
export { Installation };
