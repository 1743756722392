import { graphql } from "gatsby";
import React from "react";
import { Image } from "../Image";
import "./styles.css";

const HomeOurTeam = ({ title, team }) => {
  return (
    <div className="[ HomeOurTeam ]">
      <div className="Content md:Content_small sm:Content_medium">
        <h2 className="[ HomeOurTeam__title ] [ Font_headerBig sm:Font_headerSmall Color_darkBlue ]">
          {title}
        </h2>
        <div className="HomeOurTeam__container">
          <div className="HomeOurTeam__wrapper">
            {team.map(({ photo, name, position }) => (
              <div key={name} className="HomeOurTeam__item">
                <Image
                  className="HomeOurTeam__photo"
                  image={photo}
                  imgStyle={{ objectFit: "contain" }}
                  alt="photo"
                />
                <div className="[ HomeOurTeam__name ] [ Font_textSemiBold sm:Font_smallText Color_darkBlue ]">
                  {name}
                </div>
                <div className="[ HomeOurTeam__position ] [ Font_smallText sm:Font_smallestText Color_darkBlue48 ]">
                  {position}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

HomeOurTeam.getLivePreviewData = ({ data, getAsset }) => ({
  ourTeam: {
    ...data.ourTeam,
    team: data.ourTeam.team.map(employee => ({
      ...employee,
      photo: getAsset(employee.photo).url
    }))
  }
});

const homeOurTeamQuery = graphql`
  fragment HomeOurTeamFragment on MarkdownRemarkFrontmatter {
    ourTeam {
      title
      team {
        photo {
          childImageSharp {
            fixed(width: 400, quality: 90) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        name
        position
      }
    }
  }
`;

export { HomeOurTeam, homeOurTeamQuery };
