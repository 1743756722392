import React from "react";
import { Button } from "../Button";
import { Arrow } from "../../icons/Arrow";
import { Link } from "gatsby";

const HomeHeroSolutions = ({ solutions }) => {
  return (
    <div className="HomeHeroSolutions">
      <div className="[ HomeHeroSolutions__container ] [ Content md:Content_medium ]">
        {solutions.map(({ icon, title, text, linkTitle, href, startPrice }) => (
          //Заменить  div на link и добавить to={href}, если нужна ссылка
          <Link to={href} key={title} className="HomeHeroSolutions__card">
            <div className="HomeHeroSolutions__card-heading">
              {icon}
              <div className="[ HomeHeroSolutions__card-title ] [ Font_headerSmall Color_darkBlue ]">
                {title}
              </div>
            </div>
            <div className="[ HomeHeroSolutions__card-description ] [ Font_textRegular Color_darkBlue80 ]">
              {text}
            </div>
            <Button
              className="HomeHeroSolutions__cardButton"
              variant="outlined"
              rightIcon={<Arrow />}
            >
              {linkTitle} от {startPrice} BYN
            </Button>
          </Link>
        ))}
      </div>
    </div>
  );
};

export { HomeHeroSolutions };
