import cn from "classnames";
import React from "react";
import "./styles.css";
import { Image } from "../Image";

const WithBackground = ({ className, image, alt, children }) => {
  return (
    <div className={cn("WithBackground", className)}>
      <div className="WithBackground__backgroundContainer">
        <Image className="WithBackground__background" image={image} alt={alt} />
      </div>
      <div className="WithBackground__content">{children}</div>
    </div>
  );
};

export { WithBackground };
