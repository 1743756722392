import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@reach/tabs";
import { graphql, Link } from "gatsby";
import React, { useRef } from "react";
import { useWidth } from "../../hooks/useWidth";
import { Arrow } from "../../icons/Arrow";
import { Case } from "../../icons/Case";
import { House } from "../../icons/House";
import { Knife } from "../../icons/Knife";
import { paragraph } from "../../utils/paragraph";
import { Button } from "../Button";
import { Image } from "../Image";
import "./styles.css";

const HomeOurSolutions = ({
  title,
  houseSolutions,
  businessSolutions,
  kitchenSolutions,
}) => {
  const solutions = [
    { ...houseSolutions, icon: <House />, href: "/house/#house" },
    {
      ...businessSolutions,
      icon: <Case />,
      href: "/business",
    },
    {
      ...kitchenSolutions,
      icon: <Knife />,
      href: "/house/#kitchen",
    },
  ];

  const tabList = useRef(null);
  const tabListWidth = useWidth(tabList);
  const tabIndicatorWidth = tabListWidth / solutions.length;

  return (
    <div className="HomeOurSolutions">
      <div className="Content">
        <div className="[ HomeOurSolutions__title ] [ Font_headerBig sm:Font_headerMiddle  Color_darkBlue ]">
          {title}
        </div>

        <Tabs>
          {({ selectedIndex }) => (
            <>
              <TabPanels>
                {solutions.map(({ title, text, image, buttonLabel, href }) => (
                  <TabPanel key={title}>
                    <div className="HomeOurSolutions__solution">
                      <div className="[ HomeOurSolutions__info ] [ FadeIn ]">
                        <div>
                          <div className="[ Font_header sm:Font_headerSmall Color_darkBlue ]">
                            {title}
                          </div>
                          <div
                            className="[ HomeOurSolutions__description ] [ Font_textRegular sm:Font_smallText Color_darkBlue80 ]"
                            dangerouslySetInnerHTML={{
                              __html: paragraph(text),
                            }}
                          />
                        </div>

                        <Link to={href}>
                          <Button
                            className="HomeOurSolutions__readMoreButton"
                            rightIcon={<Arrow />}
                          >
                            {buttonLabel}
                          </Button>
                        </Link>
                      </div>

                      <Image
                        className="[ HomeOurSolutions__image ] [ FadeIn ]"
                        image={image}
                        alt=""
                      />
                    </div>
                  </TabPanel>
                ))}
              </TabPanels>
              <div ref={tabList}>
                <TabList className="HomeOurSolutions__tabs">
                  {solutions.map(({ name, icon }) => (
                    <Tab
                      key={name}
                      className="[ HomeOurSolutions__tab ] [ Font_bigText Color_darkBlue ]"
                      aria-label={`Открыть фильтры ${name}`}
                    >
                      <span className="HomeOurSolutions__tabContent_desktop">
                        {name}
                      </span>
                      <div className="HomeOurSolutions__tabContent_mobile">
                        {icon}
                      </div>
                    </Tab>
                  ))}
                  <>
                    <div
                      className="HomeOurSolutions__tabIndicator"
                      style={{
                        width: tabIndicatorWidth,
                        left: selectedIndex * tabIndicatorWidth,
                      }}
                    />
                  </>
                </TabList>
              </div>
            </>
          )}
        </Tabs>
      </div>
    </div>
  );
};

HomeOurSolutions.getLivePreviewData = ({ data, getAsset }) => ({
  ourSolutions: {
    ...data.ourSolutions,
    houseSolutions: {
      ...data.ourSolutions.houseSolutions,
      image: getAsset(data.ourSolutions.houseSolutions.image).url,
    },
    businessSolutions: {
      ...data.ourSolutions.businessSolutions,
      image: getAsset(data.ourSolutions.businessSolutions.image).url,
    },
    kitchenSolutions: {
      ...data.ourSolutions.kitchenSolutions,
      image: getAsset(data.ourSolutions.kitchenSolutions.image).url,
    },
  },
});

const homeOurSolutionsQuery = graphql`
  fragment HomeOurSolutionsFragment on MarkdownRemarkFrontmatter {
    ourSolutions {
      title
      houseSolutions {
        name
        title
        text
        buttonLabel
        image {
          childImageSharp {
            fluid(maxWidth: 1232, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      businessSolutions {
        name
        title
        text
        buttonLabel
        image {
          childImageSharp {
            fluid(maxWidth: 1232, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      kitchenSolutions {
        name
        title
        text
        buttonLabel
        image {
          childImageSharp {
            fluid(maxWidth: 1232, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export { HomeOurSolutions, homeOurSolutionsQuery };
